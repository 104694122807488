
export const CURRENT_VERSION = process.env.REACT_APP_VERSION;
export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_admin_auth_token',
    auth_user: 'dq_admin_auth_user',
    refresh_token: 'dq_admin_refresh_token',
    e2ee_krypto_pair_obj: 'dq_common_e2ee_krypto_objp',
    e2ee_krypto_disable_flg: 'dq_common_e2ee_disable_flg'
}

export const USER_TYPE = {
    REGULAR: 10,
    SUPER_USER: 1,
    ADMIN_PORTAL: 2,
    ORG_PORTAL: 3
};

export const ORG_TYPE = {
    1: 'Default',
    2: 'Company',
    3: 'School'
}

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const USER_TYPE_VERBOSE = {
    10: 'REGULAR',
    1: 'SUPER_USER',
    2: 'ADMIN_PORTAL',
    3: 'ORG_PORTAL'
};

export const USER_ACCESS_LEVEL_VERBOSE = {
    0: 'N/A',
    1: 'No Access',
    2: 'Partial Access (Admin)',
    3: 'Full Access (Admin)',
    4: 'Full Access (Analytics - Full Org)',
    5: 'Partial Access (Analytics)',
    6: 'Full Access (Analytics - Single Inst)'
};



export const NESTED_ACL_KEYS = {
    ORG_CRUD_REMOVE: 'admin_org_crud_remove',
    ORG_CRUD_CREATE: 'admin_org_crud_create',
    ORG_CRUD_UPDATE: 'admin_org_crud_update',
    ORG_ACCOUNTS_ADD: 'admin_org_accounts_add',
    ORG_ACCOUNTS_REMOVE: 'admin_org_accounts_remove',
    USERS_UPDATE_ENTITY: 'admin_users_update_entity',
    USERS_ADD: 'admin_users_add',
    USERS_REMOVE: 'admin_users_remove',
}

export const LICENSES_ORDER_STATUS_VERBOSE = {
    0: 'Not Set',
    1: 'Pending',
    2: 'Approved',
    3: 'Rejected',
    4: 'Withdrawn'
}

export const LICENSES_ORDER_STATUS = {
    NOT_SET: 0,
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    WITHDRAWN: 4,
};

export const ACL_RESOURCE_KEYS = {
    ORGANIZATION_COLLECTION: 'organization_collection',
    ORG_ACCOUNTS: 'org_accounts',
    ORG_ORDERS: 'org_orders',
    ORG_ALLORDERS: 'org_allorders',
    ORG_CONFIGS: 'org_configs',
    BADGE_UI_CONFIG: 'badge_ui_config',
    MODULE_UI_CONFIG: 'module_ui_config',
    ORG_BADGES: 'org_badges',
    ORG_MODULES: 'org_modules',
    ORG_PACKS: 'org_packs',
    VOUCHER_ALLOCATION: 'voucher_allocation',
    META_ASSESS_8: 'meta_assess_8',
    META_CATEGORY_4: 'meta_category_4',
    META_CATEGORY_11: 'meta_category_11',
    META_CATEGORY_108: 'meta_category_108',
    META_LEVEL_5: 'meta_level_5',
    META_LEVEL_6: 'meta_level_6',
    META_LEVEL_7: 'meta_level_7',
    META_LEVEL_9: 'meta_level_9',
    META_PACKAGES: 'meta_packages',
    META_TRANSLATIONS_ASSESS_8: 'meta_translations_assess_8',
    META_TRANSLATIONS_LEVEL_5: 'meta_translations_level_5',
    META_TRANSLATIONS_LEVEL_6: 'meta_translations_level_6',
    META_VOUCHERS: 'meta_vouchers',
    USERS: 'users',
    CONFIGS: 'configs',
    BUILDS: 'builds'
}

export const ACL_ENABLE_ENDPOINTS = [
    'org/list',
    'org/accounts',
    'org/orders',
    'org/allorders',
    'org/configs',
    'org/area_ui_conf',
    'org/badge_ui_conf',
    'org/all_areas',
    'org/all_badges',
    'org/all_packages',
    'org/vouchers',

    'meta_data/assess8',
    'meta_data/cat4',
    'meta_data/cat11',
    'meta_data/cat108',
    'meta_data/level3',
    'meta_data/level6',
    'meta_data/level7',
    'meta_data/level9',
    'meta_data/packages',
    'meta_data/assess8_translation',
    'meta_data/level3_translation',
    'meta_data/level6_translation',
    'meta_data/vouchers',

    'users/list',
    'settings/configs',
    'settings/builds',
]
export const ACL_RESOURCE_ROUTES_MAP = {
    'org/list': ACL_RESOURCE_KEYS.ORGANIZATION_COLLECTION,
    'org/accounts': ACL_RESOURCE_KEYS.ORG_ACCOUNTS,
    'org/orders': ACL_RESOURCE_KEYS.ORG_ORDERS,
    'org/allorders': ACL_RESOURCE_KEYS.ORG_ALLORDERS,
    'org/configs': ACL_RESOURCE_KEYS.ORG_CONFIGS,

    'org/area_ui_conf': ACL_RESOURCE_KEYS.MODULE_UI_CONFIG,
    'org/badge_ui_conf': ACL_RESOURCE_KEYS.BADGE_UI_CONFIG,
    'org/all_areas': ACL_RESOURCE_KEYS.ORG_MODULES,
    'org/all_badges': ACL_RESOURCE_KEYS.ORG_BADGES,
    'org/all_packages': ACL_RESOURCE_KEYS.ORG_PACKS,
    'org/vouchers': ACL_RESOURCE_KEYS.VOUCHER_ALLOCATION,

    'meta_data/assess8': ACL_RESOURCE_KEYS.META_ASSESS_8,
    'meta_data/cat4': ACL_RESOURCE_KEYS.META_CATEGORY_4,
    'meta_data/cat11': ACL_RESOURCE_KEYS.META_CATEGORY_11,
    'meta_data/cat108': ACL_RESOURCE_KEYS.META_CATEGORY_108,
    'meta_data/level3': ACL_RESOURCE_KEYS.META_LEVEL_5,
    'meta_data/level6': ACL_RESOURCE_KEYS.META_LEVEL_6,
    'meta_data/level7': ACL_RESOURCE_KEYS.META_LEVEL_7,
    'meta_data/level9': ACL_RESOURCE_KEYS.META_LEVEL_9,
    'meta_data/packages': ACL_RESOURCE_KEYS.META_PACKAGES,
    'meta_data/assess8_translation': ACL_RESOURCE_KEYS.META_TRANSLATIONS_ASSESS_8,
    'meta_data/level3_translation': ACL_RESOURCE_KEYS.META_TRANSLATIONS_LEVEL_5,
    'meta_data/level6_translation': ACL_RESOURCE_KEYS.META_TRANSLATIONS_LEVEL_6,
    'meta_data/vouchers': ACL_RESOURCE_KEYS.META_VOUCHERS,

    'users/list': ACL_RESOURCE_KEYS.USERS,
    'settings/configs': ACL_RESOURCE_KEYS.CONFIGS,
    'settings/builds': ACL_RESOURCE_KEYS.BUILDS,
}