import {store} from "react-notifications-component";
import {LOCAL_STORAGE_KEYS} from "./constants";

export const toastWarning = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true
        }
    });
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }

export function alphaNumericSort(key = undefined, numeric = false, reverse = false) {
    return (a, b) => {
        let valueA = key ? a[key] : a;
        let valueB = key ? b[key] : b;

        if (valueA === undefined || valueA === null) valueA = '';
        if (valueB === undefined || valueB === null) valueB = '';

        return (reverse ? valueB : valueA).toString().localeCompare((reverse ? valueA : valueB).toString(), undefined, {
            numeric: numeric,
            sensitivity: 'base'
        });
    };
}

export function removeSaltAtPos(saltedBase64String, interval = 5) {
    let cleanedString = '';
    let charsBeforeSalt = interval - 1; // Match the adjustment in addSalt

    for (let i = 0, count = 0, n = saltedBase64String.length; i < n; i++) {
        count++;

        // Add every character to the cleaned string except those at the salt positions
        if (count !== interval) {
            cleanedString += saltedBase64String[i];
        } else {
            count = 0; // Reset count after skipping a salt character
        }
    }

    return cleanedString;
}

export function save_e2ee_keys(result) {
    const e2ee_keypair_object = {pub: result.data.e2ee_pub_key, pvt: result.data.e2ee_pvt_key};
    const e2ee_obj_str = JSON.stringify(e2ee_keypair_object);
    localStorage.setItem(LOCAL_STORAGE_KEYS.e2ee_krypto_pair_obj, btoa(e2ee_obj_str));
}

export function use_e2ee() {
    return process.env.REACT_APP_E2EE_ENABLE === '1';
}

