import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";
import {use_e2ee} from "../utils/utils";

export const OrgCRUD_Services = {
    preCreateCheck: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/pre_create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { org_code };
        return invokeApi(requestObj, use_e2ee());
    },
    createEntity: (entityObject) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = entityObject;
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    reloadData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/reload_list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/org/crud/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        });
    },
    updateEntity: (entityObject) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/crud/update/' + entityObject.id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = entityObject;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const AllPackagesServices = {
    listPacks: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/package/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    mapPacks: (pack_codes, org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/package/map',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            pack_codes,
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    }
}

export const AllBadgesServices = {
    listBadges: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    mapBadges: (badge_codes, org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/map',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            badge_codes,
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    syncPackageBadges: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/badge/sync',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    }
}

export const AllAreaServices = {
    listArea: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/area/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    syncBadgeAreas: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/meta_mapping/area/sync',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    }
}

export const AreaUIConfServices = {
    sync: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/sync_meta',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj, use_e2ee());
    },
    copy: () => {

    }
}

export const BadgeUIConfServices = {
    sync: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/sync_meta',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj, use_e2ee());
    },
    copy: () => {

    }
}

export const AccountServices = {

    list_accounts: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    add_account: async (org_code, email_id, institute_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/add',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            email_id,
            institute_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    remove_account: async (org_code, member_id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/remove',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            member_id
        };
        return invokeApi(requestObj, use_e2ee());
    },

}


export const OrgConfigServices = {

    enable_ui_config: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/enable_ui_config',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    disable_ui_config: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/disable_ui_config',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    enable_dqw_access: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/enable_dqw_access',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    disable_dqw_access: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/disable_dqw_access',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

/*
ORG Config Combine Section
* */
    update_config_flags: async (org_code, flag_field_name, flag_field_value) => {

        console.log("all params in dq-admin :: " + org_code + flag_field_name + flag_field_value)

        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/configs/update_config_flags',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            flag_field_name,
            flag_field_value
        };
        return invokeApi(requestObj, use_e2ee());
    },

}


export const OrgOrderServices = {

    list_orders: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj, use_e2ee());
    },

    list_pending_orders: async () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/pending_list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
        };
        return invokeApi(requestObj, use_e2ee());
    },

    approve_order: async (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/approve',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            id
        };
        return invokeApi(requestObj, use_e2ee());
    },

    reject_order: async (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/reject',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            id
        };
        return invokeApi(requestObj, use_e2ee());
    },

    create_order: async (org_code, order_details, license_count, license_type) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/lcs_orders/create',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            order_details,
            license_count,
            license_type
        };
        return invokeApi(requestObj, use_e2ee());
    }
}

export const ProfileLookup = {
    postData: (org_code, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/profile_lookup/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            data
        };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/profile_lookup/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
        };
        return invokeApi(requestObj, use_e2ee());
    },
    updateEntity: (org_code, id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/profile_lookup/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            ...data
        };
        return invokeApi(requestObj, use_e2ee());
    },
    deleteEntity: (org_code, id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/profile_lookup/remove/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
        };
        return invokeApi(requestObj, use_e2ee());
    },
}

export const OrgSectionServices = {
    postData: (org_code, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/sections/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            data
        };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/sections/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
        };
        return invokeApi(requestObj, use_e2ee());
    },
    updateEntity: (org_code, id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/sections/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            data
        };
        return invokeApi(requestObj, use_e2ee());
    },
    deleteEntity: (org_code, id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/sections/remove/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
        };
        return invokeApi(requestObj, use_e2ee());
    },
}